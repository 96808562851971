import React, { useContext } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg_landing.jpg';
// import bg1 from 'assets/img/generic/bg-1.jpg';
// import dashboard from 'assets/img/generic/dashboard-alt.png';
import landingpage_phone_1 from 'assets/img/generic/landingpage_phone_1.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';

const Hero = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
   
        <Section
        className="pt-0 pb-0 overflow-hidden light"
        image={bg1}
        position="center bottom"
        overlay
      >
        <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={4}
            className="pb-7 pb-xl-9 text-center text-xl-start"
          >
            <h1 className="text-white fw-light">
              Bestel
              <Typed
                strings={['snel', 'eenvoudig']}
                typeSpeed={40}
                backSpeed={50}
                className="fw-bold ps-2"
                loop
              />
              <br />
              via QR code
            </h1>
            <p className="lead text-white opacity-75">
              Uw gasten kunnen via de smartphone de menukaart bekijken, bestellen & betalen. Er is geen installatie vereist.
            </p>
            <a href="https://www.wazaltzijn.be/register">
            <Button
              variant="outline-light"
              size="lg"
              className="border-2 rounded-pill mt-4 fs-0 py-2"
            >
              Registreren
              <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
            </Button>
            </a>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            className="align-self-end mt-4 mt-xl-0"
          >
            <Link to="/" className="img-landing-banner-phone">
              <img
                className="img-fluid"
                src={landingpage_phone_1}
                alt=""
              />
            </Link>
          </Col>
        </Row>
      </Section>)
};

export default Hero;
