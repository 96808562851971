import image1 from 'assets/img/generic/QR_vb_1.jpeg';
import image2 from 'assets/img/generic/illustration_uitbater.jpg';

export default [
  {
    media: { icon: "qrcode", color: '#009fe3', className: 'fs-4' },
    title: 'Als klant',
    description:
      "Scan onderstaande QR code en ervaar zelf hoe uw gasten kunnen bestellen.",
    image: image1,
    link: undefined
  },
  {
    media: { icon: "desktop", color: '#009fe3', className: 'fs-5' },
    title: 'Als uitbater',
    description:
      'Ontvang hier de bestellingen die u als klant gemaakt heeft.',
    image: image2,
    link: "/test"
  }
];
