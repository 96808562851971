import React from 'react';
import ServicesList from 'data/feature/servicesList';
import Section from 'components/common/Section';
import Service from './Service';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Services = () => (
  <Section>
    <SectionHeader
      title="Snel & Eenvoudig!"
      subtitle="Waarom kiezen voor Wazaltzijn"
    />
    {isIterableArray(ServicesList) &&
      ServicesList.map((process, index) => (
        <Service key={process.color} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Services;
