import React from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import demoList from 'data/feature/demoList';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';

const Services = () => (
  <Section bg="light" className="text-center">
    <SectionHeader
      title="Probeer het zelf"
      subtitle="Wazaltzijn is een webapplicatie en werkt dus op elk type toestel."
    />
    <Row className="mt-6">
      {demoList.map((service, index) => (
        <Col
          lg={{ span: 4, offset: (index === 0) ? 2 : 0}}
          className={className({ 'mt-6 mt-lg-0': index > 0 })}
          key={index}
        >
          <CardService {...service} />
        </Col>
      ))}
    </Row>
  </Section>
);

export default Services;
