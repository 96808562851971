import React from 'react';
import Hero from './Hero';
import NavbarStandard from '../NavbarStandard';
// import Partners from './Partners';
import Processes from './Processes';
import Services from './Services';
// import Testimonial from './Testimonial';
import Demo from './Demo';
// import Cta from './Cta';
import FooterStandard from '../FooterStandard';
import Pricing from './Pricing';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      {/* <Partners /> */}
      <Processes />
      <Demo />
      <Services />
      {/* <Testimonial /> */}
      {/* <Cta /> */}
      <Pricing />
      <FooterStandard />
     
    </>
  );
};

export default Landing;
