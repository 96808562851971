import React, { useContext } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg_landing.jpg';
import slide1 from 'assets/img/generic/slide1.jpeg';
import slide2 from 'assets/img/generic/slide2.jpeg';
import slide3 from 'assets/img/generic/slide3.jpeg';
// import dashboard from 'assets/img/generic/dashboard-alt.png';
import verhuurprinter from 'assets/img/generic/verhuurprinter.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';

const Hero = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  function onEmailClick() {
      window.open(`mailto:info@wazaltzijn.be`);
  }
  return (
   
        <Section
          className="pt-0 pb-0 overflow-hidden light"
          image={bg1}
          position="center bottom"
          overlay
        >
        <Row className="justify-content-center align-items-center pt-8 pt-lg-8 pb-lg-9 pb-xl-0">
          <Col
            xs={11}
            lg={8}
            xl={4}
            className="pb-7 pb-xl-9 text-center text-xl-start"
          >
            <h1 className="text-white fw-light">
              Huur een printer
              <br />
              voor uw evenement
            </h1>
            <p className="lead text-white opacity-75">
              
            </p>
            <Button
              variant="outline-light"
              size="lg"
              className="border-2 rounded-pill mt-4 fs-0 py-2"
              onClick={onEmailClick}
            >
              Printer aanvragen
              <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
            </Button>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            xs={12}
            className="align-self-end pb-4 pt-4"
            style={{ textAlign: 'center' }}
          >

            <Carousel
              className='theme-slider'
              nextIcon={
                <FontAwesomeIcon icon="angle-right" />
              }
              prevIcon={
                <FontAwesomeIcon icon="angle-left" />
              }
            >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={slide1}
                  alt="First slide"
                  
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={slide2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={slide3}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
      
          </Col>
        </Row>
      </Section>)
};

export default Hero;
