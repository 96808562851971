import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";

const CardService = ({ link, media, title, description, children, image }) => (

    <a href={link} style={{ textDecoration: 'none' }}>
    <Card className="card-span h-100">
      <div className="card-span-img">
        <FontAwesomeIcon
          icon={media.icon}
          style={{color: media.color}}
          className={className(
            media.className
          )}
        />
      </div>
      <Card.Body className="pt-6 pb-4">
        <h5 className="mb-2">{title}</h5>
        {description && <p>{description}</p>}
        {children}
        <Image fluid className="px-6 px-md-0 p-3" src={image} alt="" style={{maxHeight:"300px"}}/>
      </Card.Body>
    </Card>
  </a>
);

CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

export default CardService;
