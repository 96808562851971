import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Section from 'components/common/Section';
import SectionHeader from './SectionHeader';
import verhuurprinter from 'assets/img/generic/verhuurprinter.png';

const Pricing = () => {
    const isFeatured = false;

    return (
        <Section>
             <SectionHeader
                title="Prijzen"
                subtitle=""
                />
                <Row className="g-0">
                    <Col
                    lg={{ span: 4, offset: 2 }}
                    className={classNames('border-top border-bottom', {
                        'dark__bg-1000 px-4 px-lg-0': isFeatured
                    })}
                    style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
                    >
                        <div className="h100" style={{ backgroundColor: 'white' }}>
                        <div className="text-center p-4">
                                <h3 className="fw-normal my-0">Weekend</h3>
                                {/* <p className="mt-3">Eenmalige evenemeten</p> */}
                                <h2 className="fw-medium my-4">
                                    <sup className="fw-normal fs-2 me-1">€</sup>
                                    180
                                    <small className="fs--1 text-700">/ weekend</small>
                                </h2>
                            </div>
                            <hr className="border-bottom-0 m-0" />
                        <div className="text-center p-4">
                                <h3 className="fw-normal my-0">Maand</h3>
                                {/* <p className="mt-3">Tijdelijke evenementen</p> */}
                                <h2 className="fw-medium my-4">
                                    <sup className="fw-normal fs-2 me-1">€</sup>
                                    250
                                    <small className="fs--1 text-700">/ maand</small>
                                </h2>
                            </div>
                            <hr className="border-bottom-0 m-0" />
                            <div className="text-start px-sm-4 py-4">
                                <h5 className="fw-medium fs-0">Inbegrepen:</h5>
                                <ul className="list-unstyled mt-3">
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        QR labels digitaal
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Internet {' '}
                                        <SoftBadge pill bg="primary">
                                            4G
                                        </SoftBadge>
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Levering
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Hulp bij opstart
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col
                    lg={{ span: 4}}
                    style={{ textAlign: 'center' }}
                    >
                        <img
                            className="img-fluid p-6"
                            src={verhuurprinter}
                            alt=""
                        />
                    </Col>
                </Row>
            </Section>
    );
};

export default Pricing;
