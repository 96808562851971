import illustration1 from 'assets/img/generic/icon_scan.png';
import illustration2 from 'assets/img/generic/icon_order.png';
import illustration3 from 'assets/img/generic/icon_pay.png';

export default [
  {
    icon: ['far', 'arrow-right'],
    iconText: '',
    color: 'danger',
    title: '1. QR code scannen',
    description:
      '',
    image: illustration1
  },
  {
    icon: ['far', ''],
    iconText: '',
    color: 'info',
    title: '2. Bekijk de menu kaart & Bestel',
    description:
      "",
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', ''],
    iconText: '',
    color: 'success',
    title: '3. Kies uw betaalmethode',
    description:
      '',
    image: illustration3
  }
];
