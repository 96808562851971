import React from 'react';
import Hero from './Hero';
import Pricing from './Pricing';
import NavbarStandard from '../NavbarStandard';
// import Partners from './Partners';
// import Processes from './Processes';
// import Services from './Services';
// import Testimonial from './Testimonial';
// import Demo from './Demo';
// import Cta from './Cta';
import FooterStandard from '../FooterStandard';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      <Pricing />
      <FooterStandard />
    </>
  );
};

export default Landing;
