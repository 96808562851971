import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';
import { Row } from 'react-bootstrap';
import classNames from 'classnames';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Hoe werkt Wazaltzijn?"
      subtitle="Laat uw gasten via de smartphone bestellen"
    />
     <Row
      className=""
    >
      {isIterableArray(processList) &&
        processList.map((process, index) => (
          <Process key={process.color} isFirst={index === 0} {...process} />
        ))}
    </Row>
  </Section>
);

export default Processes;
