import illustration1 from 'assets/img/generic/kenmerken1_menukaart.png';
import illustration2 from 'assets/img/generic/kenmerken2_qrlabels.png';
import illustration3 from 'assets/img/generic/kenmerken3_bestellingen.png';
import illustration4 from 'assets/img/generic/kenmerken4_statistieken.png';


export default [
  {
    icon: '',
    iconText: '',
    color: 'danger',
    title: 'Menukaart',
    description:
    ['Maak gemakkelijk producten aan: ',
     <ul>
        <li>Gebruik maken van productopties</li>
        <li>Foto & omschrijving toevoegen</li>
        <li>Onderverdelen in groepen</li>
        <li>Producten snel verbergen of tonen</li>
      </ul>],
    image: illustration1
  },
  {
    icon: '',
    iconText: '',
    color: 'danger',
    title: 'QR Labels',
    description:
      "Benoem al uw tafels en genereer direct alle QR labels",
    image: illustration2,
    inverse: true
  },
  {
    icon: '',
    iconText: '',
    color: 'danger',
    title: 'Bestellingen',
    description:
      "Het is mogelijk om de bestellingen te splitsen naar meerdere locaties. De bestelling zijn online te beheren of maak gebruik van onze labelprinter om de bestellingen automatisch af te printen.",
    image: illustration3,
    inverse: false
  },
  {
    icon: '',
    iconText: '',
    color: 'danger',
    title: 'Statistieken',
    description:
      "Via ons online platform is het mogelijk om de historiek van de bestellingen te raadplegen of het verbruik van een bepaalde periode te tonen.",
    image: illustration4,
    inverse: true
  }
];
