import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Section from 'components/common/Section';
import SectionHeader from './SectionHeader';

const Pricing = () => {
    const isFeatured = false;

    return (
        <Section className="bg-light">
             <SectionHeader
                title="Prijzen"
                subtitle=""
                />
                <Row className="g-0">
                <Col
                    lg={{ span: 4, offset: 2 }}
                    className={classNames('border-top border-bottom text-white')}
                    
                    >
                        <div className="h100" style={{ "height" : "100%", backgroundImage: "linear-gradient(to bottom right, #4274b9, #0c2442)" }}>
                        <div className="text-center p-4">
                                <h3 className="fw-normal my-0 text-white">Basis</h3>
                                <p className="mt-3">1ste maand <b>GRATIS</b>*</p>
                                <h2 className="fw-medium my-4 text-white">
                                    <sup className="fw-normal fs-2 me-1">€</sup>
                                    18
                                    <small className="fs--1 text-700 text-white">/ maand</small>
                                </h2>
                            </div>
                            <hr className="border-bottom-0 m-0" />
                            <div className="text-start px-sm-4 py-4">
                                <ul className="list-unstyled mt-3">
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Registreer en begin meteen
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Digitale menukaart
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Download QR labels
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Bestellen als ober
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Meerdere schermen mogelijk
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Meerdere printers mogelijk
                                    </li>
                                    
                                </ul>
                                *Excl transactie kosten:
                                <ul>
                                    <li>
                                        Payconiq (€0,06)
                                    </li>
                                    <li>
                                        Bancontact (€0,12)
                                    </li>
                                    <li>
                                        iDEAL (€0,12)  
                                    </li>
                                    <li>
                                        Cash (Gratis)
                                    </li>
                                </ul>
                                <small>Abonnement opzeggen zonder extra kosten</small>
                            </div>
                        </div>
                    </Col>
                    <Col
                    lg={{ span: 4 }}
                    className={classNames('border-top border-bottom', {
                        'dark__bg-1000 px-4 px-lg-0': isFeatured
                    })}
                    style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
                    >
                        <div className="h100" style={{ backgroundColor: 'white' }}>
                        <div className="text-center p-4">
                                <h3 className="fw-normal my-0">Module: Afhaling in-store</h3>
                                {/* <p className="mt-3">Tijdelijke evenementen</p> */}
                                <h2 className="fw-medium my-4">
                                    <sup className="fw-normal fs-2 me-1">€</sup>
                                    5
                                    <small className="fs--1 text-700">/ maand</small>
                                </h2>
                            </div>
                            <hr className="border-bottom-0 m-0" />
                            <div className="text-start px-sm-4 py-4">
                                <h5 className="fw-medium fs-0">Inbegrepen:</h5>
                                <ul className="list-unstyled mt-3">
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Combineerbaar met bediening
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        SMS berichten bewerkbaar
                                    </li>
                                    <li className="py-1">
                                        <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                        Behandelingskost per bestelling instelbaar
                                    </li>
                                </ul>
                                *Excl:
                                <ul>
                                    <li>
                                        SMS kosten (€0,15/SMS)
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>

                 
                </Row>
            </Section>
    );
};

export default Pricing;
